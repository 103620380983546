/* eslint-disable tailwindcss/no-custom-classname */
import { useState } from 'react'
import Image from 'next/image'

import clsx from 'clsx'
import { NumericFormat } from 'react-number-format'
import { faAngleRight } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { StrapiRootSingleType, StrapiUploadFileEntity } from '@/types/strapi'

export interface OptionCardProps {
  circleColor?: string
  icon?: StrapiRootSingleType<Partial<StrapiUploadFileEntity>>
  image?: string
  label: string
  value?: string
  detailsExpiration?: string
  angleRight?: boolean
}

const OptionCard = ({
  circleColor = 'bg-pink-200',
  image,
  icon,
  label,
  value,
  detailsExpiration,
  angleRight = true,
}: OptionCardProps) => {
  const [pressed, setPressed] = useState(false)

  const handleButtonClick = () => {
    setPressed(true)
    setTimeout(() => setPressed(false), 300)
  }

  return (
    <div
      onClick={handleButtonClick}
      className={clsx(
        'transition duration-500 ease-in-out',
        pressed ? 'scale-95' : 'scale-100'
      )}
    >
      <div className="card shadow-card min-h-[64px] w-full cursor-pointer">
        <div className="flex h-full items-center justify-between gap-2 pl-2">
          <div className="flex w-full items-center gap-4">
            {icon && (
              <div
                className="flex h-12 w-12 items-center justify-center rounded-full"
                style={{
                  background: circleColor,
                }}
              >
                <div className="flex w-8 items-center justify-center">
                  <Image
                    height={25}
                    width={25}
                    src={icon.data.attributes.url || '/default-icon.png'} // Fallback URL
                    alt="Service card icon"
                  />
                </div>
              </div>
            )}
            {image && (
              <div
                className="flex items-center justify-center rounded-full"
                style={{
                  background: circleColor,
                }}
              >
                <div className="flex items-center justify-center">
                  <Image
                    src={image}
                    alt={label}
                    width={150}
                    height={150}
                    className="aspect-[3/2] w-14 object-contain"
                  />
                </div>
              </div>
            )}
            {value && detailsExpiration ? (
              <div className="flex w-full flex-col gap-4 px-2">
                <span className="text-base font-medium">{label}</span>
                <div className="flex w-full justify-between">
                  <span className="text-lg font-bold">
                    Valor:{' '}
                    <NumericFormat
                      value={value}
                      displayType="text"
                      thousandSeparator
                      prefix={'$ '}
                      decimalScale={2}
                      allowNegative={false}
                      className="text-lg font-bold"
                    />
                  </span>
                  <span className="text-base font-medium">
                    Vigencia: {detailsExpiration}
                  </span>
                </div>
              </div>
            ) : (
              <span className="text-base font-medium">{label}</span>
            )}
          </div>
          {angleRight && (
            <div className="mr-5 w-3">
              <FontAwesomeIcon icon={faAngleRight} />
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

export default OptionCard
