/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react'
import { GetStaticPropsResult } from 'next'
import { Red_Hat_Display } from 'next/font/google'
import Image from 'next/image'
import Link from 'next/link'
import { useRouter } from 'next/router'

import Sheet from 'react-modal-sheet'
import { faXmark } from '@fortawesome/pro-regular-svg-icons'

import StrapiClient from '@/services/Strapi/Client'
import HomeTopUpQuery from '@/services/Strapi/Queries/Hometopup.query'
import useInactivityScreen from '@/hooks/useInactivityScreen'

import IconButton from '@/components/ui/atoms/IconButton'
import OptionCard from '@/components/ui/atoms/OptionCard'
import ServiceCard from '@/components/ui/atoms/ServiceCard'
import Title from '@/components/ui/atoms/Title'
import SearchInputHome from '@/components/ui/molecules/SearchInputHome'
import Slider from '@/components/ui/molecules/Slider'

import { useSearchHome } from '@/context/SearchHomeContext'
import { options } from '@/types/strapi/Components.types'
import { HomeTopUpEntity } from '@/types/strapi/HomeComponents.types'
import { HomeTopUpQueryInterface } from '@/types/strapi/Queries.types'

interface IServices {
  hometopup: HomeTopUpEntity
}
const RedHatDisplay = Red_Hat_Display({
  subsets: ['latin'],
  display: 'swap',
})
const Services = ({
  hometopup: { principalBanner, collectionCard, rechargeCard, searchHome },
}: IServices) => {
  const router = useRouter()
  const { setFilterServiceData } = useSearchHome()
  const { mutate } = useInactivityScreen()

  const [menu, setMenu] = useState({
    mobileRecharges: false,
  })

  const [serviceFilter, setServiceFilter] = useState<string>('')
  const [serviceCardInfo, setServiceCardInfo] = useState({
    title: '',
    index: 0,
  })

  const closeBottomSheet = () => {
    setMenu((prev) => ({
      ...prev,
      mobileRecharges: false,
      publicServices: false,
    }))
  }

  const optionsMobileRechargesItems = rechargeCard.find(
    ({ index }) => index === 70
  )

  useEffect(() => {
    mutate()
    sessionStorage.setItem('lastPath', router.asPath)
  }, [router.asPath])

  useEffect(() => {
    mutate()
    rechargeCard.map(({ index, slug }) => {
      if (serviceCardInfo.index === 70) {
        setMenu((prev) => ({ ...prev, mobileRecharges: true }))
      } else if (serviceCardInfo.index === index) {
        setFilterServiceData(undefined)
        router.push(slug)
      }
    })
  }, [serviceCardInfo])

  return (
    <>
      <Title isVisible={true} fontSize="xl" title="Pagos y Recargas" />
      {principalBanner.length >= 2 ? (
        <Slider slides={principalBanner} />
      ) : (
        <div className="w-full">
          {principalBanner.map(
            (
              {
                href,
                image,
                imageMobile,
                description,
                aspectRatioDesktop,
                aspectRatioMobile,
              },
              key
            ) => {
              return (
                <Link key={key} href={href} title={description}>
                  <div
                    className="shadow-card relative hidden w-full cursor-pointer rounded-xl md:block"
                    style={{
                      aspectRatio: aspectRatioDesktop,
                    }}
                  >
                    <Image
                      fill
                      className="rounded-xl"
                      priority
                      src={image.data.attributes.url || ''}
                      alt={description || ''}
                    />
                  </div>
                  <div
                    className="shadow-card relative block w-full cursor-pointer rounded-xl md:hidden"
                    style={{
                      aspectRatio: aspectRatioMobile,
                    }}
                  >
                    <Image
                      fill
                      className="rounded-xl"
                      priority
                      src={imageMobile.data.attributes.url || ''}
                      alt={description || ''}
                    />
                  </div>
                </Link>
              )
            }
          )}
        </div>
      )}
      <SearchInputHome
        setServiceFilter={setServiceFilter}
        serviceData={searchHome}
        serviceFilter={serviceFilter}
      />
      <div className="mb-[180px] mt-2 grid grid-cols-3 gap-4 sm:gap-5 md:grid-cols-4 md:gap-6 xl:grid-cols-5">
        {rechargeCard.map(
          ({ icon, circleColor, isActive, index, label }, key) => (
            <>
              {isActive && (
                <div
                  key={key}
                  onClick={() => {
                    setServiceCardInfo({
                      title: label,
                      index: index,
                    })
                  }}
                >
                  {isActive && (
                    <ServiceCard
                      icon={icon}
                      circleColor={circleColor}
                      title={label}
                    />
                  )}
                </div>
              )}
            </>
          )
        )}
        {collectionCard.map(
          ({ icon, label, circleColor, isActive, slug }, key) => (
            <>
              {isActive && (
                <div
                  key={key}
                  onClick={() => {
                    setFilterServiceData(undefined)
                    router.push(slug)
                  }}
                >
                  {isActive && (
                    <ServiceCard
                      icon={icon}
                      circleColor={circleColor}
                      title={label}
                    />
                  )}
                </div>
              )}
            </>
          )
        )}
      </div>

      {optionsMobileRechargesItems && (
        <Sheet
          isOpen={menu.mobileRecharges}
          onClose={closeBottomSheet}
          detent="content-height"
          className={`mx-auto w-full md:max-w-[640px] lg:max-w-4xl ${RedHatDisplay.className}`}
        >
          <Sheet.Container>
            <div className="flex">
              <div className="w-14"></div>
              <Sheet.Header />
              <div className="w-14">
                <IconButton
                  type="button"
                  icon={faXmark}
                  onClick={closeBottomSheet}
                  size="lg"
                />
              </div>
            </div>

            <Sheet.Content className="no-scrollbar">
              <div className="flex flex-col gap-2 overflow-y-auto px-5">
                <h3 className="text-xl font-bold">{serviceCardInfo.title}</h3>
                <div className="flex flex-col gap-4 pb-10">
                  {optionsMobileRechargesItems.options.map(
                    (
                      { circleColor, slug, icon, label }: options,
                      key: number
                    ) => (
                      <div
                        key={key}
                        onClick={() => setFilterServiceData(undefined)}
                      >
                        <Link href={slug}>
                          <OptionCard
                            label={label}
                            icon={icon}
                            circleColor={circleColor}
                          />
                        </Link>
                      </div>
                    )
                  )}
                </div>
              </div>
            </Sheet.Content>
          </Sheet.Container>

          <Sheet.Backdrop />
        </Sheet>
      )}
    </>
  )
}

export default Services

export async function getStaticProps(): Promise<
  GetStaticPropsResult<IServices>
> {
  const strapi = await StrapiClient.query<HomeTopUpQueryInterface>({
    query: HomeTopUpQuery,
  })

  return {
    props: {
      hometopup: strapi.data.paymentsTopUp.data.attributes || {},
    },
    revalidate: 60,
  }
}
